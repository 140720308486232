'use client'

import {
	Component, ErrorInfo, ReactNode
} from 'react'

interface AsyncBoundaryErrorWrapViewProps {
	props: {
		children: ReactNode;
		fallback: ReactNode;
	};
	state: {
		error: Error;
		hasError: boolean;
	};
}

class ErrorBoundaryView extends Component<AsyncBoundaryErrorWrapViewProps[`props`], AsyncBoundaryErrorWrapViewProps[`state`]> {
	constructor (props: AsyncBoundaryErrorWrapViewProps[`props`]) {
		super(props)

		this.state = {
			error: {
				message: ``,
				name: ``,
				stack: ``
			},
			hasError: false
		}
	}

	static getDerivedStateFromError (error: Error) {
		return {
			error,
			hasError: true
		}
	}

	static componentDidCatch (error: Error, errorInfo: ErrorInfo) {
		console.error(`Uncaught error:`, error, errorInfo)
	}

	render () {
		const {
			state, props
		} = this

		if (state.hasError === true) {
			const appConfigData = JSON.parse(process.env.appConfig ?? `{"npm_package_name":""}`) as {
				appEnv: `pretest` | `test` | `production`;
				npm_package_name: `live` | `member`;
			}

			// STG
			const phaseTest = appConfigData.appEnv === `test`

			const customError = state.error

			if (typeof Error !== `undefined`) {
				if (process.env.NODE_ENV === `development`) {
					console.log(
						customError,
						customError.name,
						customError.stack,
						customError.message
					)
				} else if (phaseTest === true) {
					let serverUrl = process.env.memberUrl

					if (appConfigData.npm_package_name === `live`) {
						serverUrl = process.env.liveUrl
					}

					fetch(`${serverUrl}/api-message?msg=${JSON.stringify({
						message: JSON.stringify(customError),
						name: `[AsyncBoundary] ${customError.name}`,
						stack:
							`
								Error: ${JSON.stringify(customError.message)}
								Stack: ${JSON.stringify(customError.stack)}
							`
					})}`)
				}
			}

			return props.fallback
		}

		return props.children
	}
}

export default ErrorBoundaryView
